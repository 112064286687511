import React, { useState, useEffect } from "react";
import axios from "axios";
import warning from "./assets/warning.png";

import "./App.css";
import LeaderboardEntry from "./components/LeaderboardEntry";
import { CircularProgress } from "@mui/material";
import podium from "./assets/podium.png";
import calendar from "./assets/calendar.png";
import Lottie from "lottie-react";
import share from "./assets/animations/people.json";
import TreeProgressBar from "./components/TreeProgressBar";
import PopUp from "./components/PopUp";

function App() {
  const [loading, setLoading] = useState(true);
  const [allLeaderboards, setAllLeaderboards] = useState<any>();
  const [fadeIn, setFadeIn] = useState(false);
  const [error, setError] = useState(false);
  const [initialising, setIniitalising] = useState(true);
  const [showToggle, setShowToggle] = useState(false);
  const [userLeaderboard, setUserLeaderboard] = useState(null);
  const [groupLeaderboard, setGroupLeaderboard] = useState<any>(null);
  const [selectedButton, setSelectedButton] = useState<string>("FRIENDS"); // Initialize as an empty string
  const [shouldFade, setShouldFade] = useState(false);

  // Add this inside the App component body:
  const [selectedEntry, setSelectedEntry] = useState<null | {
    name: string;
    trips: number;
    treesPlanted: number;
    profilePicture: string;
  }>(null);

  useEffect(() => {
    if (shouldFade) {
      // If shouldFade is true, set it back to false after the duration of the transition
      setTimeout(() => {
        setShouldFade(false);
      }, 300);
    }
  }, [shouldFade]);

  const renderToggleComponent = () => {
    return (
      <div className="grid grid-cols-2 items-center justify-center mt-5 mb-3 font-montserrat">
        <button
          className={`px-4 py-2 h-full rounded-md shadow-lg text-sm md:text-base ${
            selectedButton === "FRIENDS" ? "bg-primary text-white" : "bg-gray-300 text-primary"
          }`}
          onClick={() => setSelectedButton("FRIENDS")}
        >
          Friends Leaderboard
        </button>
        <button
          className={`ml-3 px-4 h-full py-2 rounded-md shadow-lg text-sm md:text-base ${
            selectedButton === "GROUP" ? "bg-primary text-white" : "bg-gray-300 text-primary"
          }`}
          onClick={() => setSelectedButton("GROUP")}
        >
          {groupLeaderboard.leaderboardName}
        </button>
      </div>
    );
  };

  const renderShareBlock = () => {};

  useEffect(() => {
    console.log("This is the group lb", groupLeaderboard);
  }, [showToggle]);

  useEffect(() => {
    if (allLeaderboards && allLeaderboards.length > 0) {
    }
  }, [allLeaderboards]);

  const loadLeaderboards = () => {
    // setFadeIn(false);
    // Parse the query string
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of 'u'
    const u = urlParams.get("u");
    const s = urlParams.get("start");
    const e = urlParams.get("end");
    const g = urlParams.get("g");
    const d = urlParams.get("dev");
    setLoading(true);
    const url = d ? process.env.REACT_APP_LB_GEN_URL : process.env.REACT_APP_LB_GEN_URL;
    console.log(url);
    if (url !== undefined) {
      // Create the headers object
      let headers = {};
      let body: any = {
        dateRange: {
          start: "2023-01-01",
          end: "2030-01-01",
        },
      };
      // If 'u' is defined, add it to the headers
      if (u) {
        headers = {
          Authorization: `Bearer ${u}`,
        };
      } else if (g) {
        body = { ...body, group: g };
      }

      axios({
        method: "POST",
        url: url,
        headers: headers,
        data: body,
      })
        .then((response: any) => {
          setTimeout(() => {
            console.log("Loaded");
            setAllLeaderboards(response.data.leaderboards);

            // Check if both 'GROUP' and 'USER' types exist in the leaderboardsData
            const hasGroup = response.data.leaderboards.some((leaderboard: any) => leaderboard.type === "GROUP");
            const hasUser = response.data.leaderboards.some((leaderboard: any) => leaderboard.type === "USER");
            const gl = response.data.leaderboards.find((leaderboard: any) => leaderboard.type === "GROUP");
            const ul = response.data.leaderboards.find((leaderboard: any) => leaderboard.type === "USER");
            // gl.leaderboardConfig = {
            //   treeProgressBar: {
            //     showProgressBar: true,
            //     maxValue: 100000,
            //     currentValue: 5000,
            //     UI: {},
            //   },
            // };
            setGroupLeaderboard(gl);
            console.log(gl);

            setUserLeaderboard(ul);
            console.log(hasGroup, hasUser);
            if (hasGroup && hasUser) {
              setShowToggle(true);

              // Find the first 'GROUP' and 'USER' objects respectively
            } else {
              console.log("here");
              setShowToggle(false);
              // setGroupLeaderboard(null);
              // setUserLeaderboard(null);
            }
            setLoading(false);
            setError(false);
          }, 1000);
          setTimeout(() => {
            setFadeIn(true);
          }, 1500);
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setFadeIn(true);
          }, 1500);
        });
    } else {
      console.log("Here2");

      setError(true);
      setFadeIn(true);
    }
  };

  useEffect(() => {
    if (initialising) {
      console.log("Initialising");
      setIniitalising(false);
      loadLeaderboards();
    }
  }, []);

  const renderCoverSection = (lb: any) => {
    const lbUIConfig = lb.leaderboardConfig?.UI ?? { sections: [] };

    return (
      <>
        <div className="">
          {lbUIConfig.sections.map((section: any) => {
            return (
              <div className="flex-row w-full items-center justify-center">
                <h1
                  style={{ backgroundColor: lbUIConfig.headingColor ?? "white", minWidth: "150px" }}
                  className="text-3xl font-bold text-primary"
                >
                  {lb.leaderboardName}
                </h1>
                <br />
                <a href={section.img.onClickUri ?? null} target="_blank" rel="noopener noreferrer">
                  <img
                    src={section.img?.uri ?? null}
                    alt="Tour De Insurtech 2023"
                    className="mx-auto" // Center the image horizontally
                    style={{ maxWidth: section.img.width ?? "20%", minWidth: "150px" }} // Make sure the image fits the container
                  />
                </a>
                <br />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderLeaderboard = (lb: any) => {
    console.log("Rendering this: ", lb);
    const lbUIConfig = lb.leaderboardConfig?.UI ?? {};

    return (
      <>
        {lb ? (
          <div
            // style={{ backgroundColor: lbUIConfig.backgroundColor ?? "white", maxWidth: "100%" }}
            className={`flex flex-col mx-auto bg-white/90 rounded-lg px-5 font-text  ${
              shouldFade ? "opacity-0" : "opacity-100"
            } transition-opacity duration-300`}
          >
            {showToggle && renderToggleComponent()}
            {renderCoverSection(lb)}
            <div className="flex-grow min-w-[16rem] md:min-w-[45rem] max-w-5xl mx-auto">
              <div className=" pb-3 ">
                <div className="flex items-center  justify-center mt-5 mx-5 pb-2 pt-4 ">
                  <img src={podium} width="30" height="30" className="" />
                  <p className="text-2xl md:text-3xl font-text text-primary font-semibold text-center mx-3 ">
                    {lb.leaderboardName.toUpperCase()}
                  </p>
                </div>
                {lb.leaderboardConfig && lb.leaderboardConfig.leaderboardPeriod != "" && (
                  <div className="flex items-center justify-center mx-5 pb-2 ">
                    <img src={calendar} width="20" height="20" className="" />
                    <p className="text-sm md:text-base font-text font-primary  mx-2 ">
                      {lb.leaderboardConfig.leaderboardPeriod}
                    </p>
                  </div>
                )}
              </div>

              {lb.leaderboardDescription && lb.leaderboardDescription != "" && (
                <p className="text-sm md:text-base font-text font-primary pb-4 mx-3  ">{lb.leaderboardDescription}</p>
              )}
              {lb.leaderboardConfig && lb.leaderboardConfig.treeProgressBar && (
                <TreeProgressBar treeProgressBar={lb.leaderboardConfig.treeProgressBar} />
              )}

              <div
                // style={{ backgroundColor: "#87CEEB" }}
                className="flex-grow  mt-4 bg-secondary rounded-xl pt-3 pb-3 px-4 mb-5"
              >
                <h1
                  style={{ color: "black" }}
                  className=" font-primary font-semibold text-lg md:text-2xl text-left items-center py-2 pl-5 "
                >
                  LATEST RANKINGS{" "}
                </h1>
                {lb.headers && (
                  <div className="grid grid-cols-custom mx-5 items-center py-4 ">
                    {lb.headers.map((head: any) => {
                      return (
                        <>
                          <p
                            className={` justify-left flex  mx-1 items-center text-sm md:text-base text-black font-semibold `}
                          >
                            {head.toUpperCase()}
                          </p>
                        </>
                      );
                    })}
                  </div>
                )}
                {lb.lbList
                  .filter((item: any) => item !== null)
                  .map((item: any, index: number) => {
                    console.log("item");

                    return (
                      <LeaderboardEntry
                        item={{ ...item, rank: index + 1 }}
                        // onClick={() => {
                        //   console.log("Leaderboard entry clicked!", item);
                        //   setSelectedEntry({
                        //     name: item.name, // Adjust these based on your data structure
                        //     trips: item.totalTrips,
                        //     treesPlanted: item.sumTotalTreesPlanted,
                        //     profilePicture: item.profilePic, // Assuming profilePic holds the URL
                        //   });
                        //   // Add your click handling logic here
                        // }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div>
      <div
        className={`w-screen h-full bg-primary flex justify-center items-center transition-opacity duration-500 absolute ${
          loading ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex flex-col justify-center items-center ">
          <CircularProgress sx={{ color: "#07EFD3" }} size={40} />
          <p className="pt-10 font-text text-xl font-bold text-secondary">LOADING THE LEADERBOARD</p>
        </div>
      </div>
      <div
        className={`w-full min-h-full bg-primary flex flex-cols text-center transition-opacity  duration-500 absolute ${
          fadeIn ? "opacity-100 " : "opacity-0 "
        }`}
      >
        {!error ? (
          <div
            className={`flex flex-col justify-center items-center mx-auto max-w-[700px]  ${
              shouldFade ? "opacity-0" : "opacity-100"
            } transition-opacity duration-300`}
          >
            {!showToggle ? (
              groupLeaderboard ? (
                renderLeaderboard(groupLeaderboard)
              ) : userLeaderboard ? (
                renderLeaderboard(userLeaderboard)
              ) : (
                <p>Something is wrong with the logic</p>
              )
            ) : selectedButton === "GROUP" ? (
              // Render the group leaderboard when "GROUP" button is selected
              groupLeaderboard && renderLeaderboard(groupLeaderboard)
            ) : (
              // Render the user leaderboard when "FRIENDS" button is selected
              userLeaderboard && renderLeaderboard(userLeaderboard)
            )}
          </div>
        ) : (
          <div className="flex flex-col mx-auto justify-center items-center bg-white px-10">
            <img src={warning} alt="Error" className="w-32 h-32 mb-3" /> {/* Adjust the classes as per your need */}
            <div className="text-2xl font-text text-primary pt-5 px-5">We could not load your leaderboard!</div>
            <div className="text-lg font-text text-darkgrey pt-5 px-5">Please try again in a moment.</div>
            <button
              className="bg-primary mt-10 font-text text-white shadow-md px-4 py-3 rounded-md"
              onClick={loadLeaderboards}
            >
              Try again
            </button>
            {/* <LeaderboardEntry /> */}
          </div>
          // </div>
        )}
      </div>
      {selectedEntry && <PopUp data={selectedEntry} onClose={() => setSelectedEntry(null)} />}
    </div>
  );
}

export default App;
