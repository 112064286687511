// PopUp.tsx

import React from 'react';

interface PopUpProps {
  data: {
    name: string;
    trips: number;
    treesPlanted: number;
    profilePicture: string;
  };
  onClose: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ data, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <button onClick={onClose}>Close</button>
        <div className="flex items-center mt-4">
          <img src={data.profilePicture} alt="Profile" className="w-16 h-16 rounded-full" />
          <div className="ml-4">
            <h2>{data.name}</h2>
            <p>Trips: {data.trips}</p>
            <p>Trees Planted: {data.treesPlanted}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUp;