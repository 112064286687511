import userImage from "../assets/user.png";
import firstprize from "../assets/firstprize.png";
import secondprize from "../assets/secondprize.png";
import thirdprize from "../assets/thirdprize.png";

function LeaderboardEntry({ item, onClick }: any) {
  console.log(item);
  return (
    <div
      className={`leaderboard-entry grid grid-cols-custom my-2 rounded-md p-3  shadow-sm mx-2 items-center font-text ${
        (item.rank + 1) % 2 !== 0 ? `bg-white/80` : `bg-white`
      }
      ${(item.rank + 1) % 2 !== 0 ? `bg-white/80` : `bg-white`}
      ${item.self ? `border-4 border-yellow-500` : ``}`}
      key={item.firstName + item.surname}
      onClick={onClick}
    >
      <div className="relative flex items-center justify-center">
        {item.rank == 1 ? (
          <img src={firstprize} width={50} height={50} />
        ) : item.rank == 2 ? (
          <img src={secondprize} width={50} height={50} />
        ) : item.rank == 3 ? (
          <img src={thirdprize} width={50} />
        ) : (
          <p className={`text-base md:text-lg text-darkgrey absolute bg-blue-40 font-semibold`}>{item.rank}</p>
        )}
      </div>

      <div className="h-12 md:h-16 flex  ">
        <img
          src={item.profilePhotoUrl ? item.profilePhotoUrl : userImage}
          className="rounded-full flex my-auto mx-auto max-h-12 md:max-h-16"
        />
      </div>
      <p className="text-lg md:text-xl text-darkgrey pl-2  mr-5 text-left ">
        {item.firstName} {item.surname}
      </p>
      <p className="text-sm md:text-lg text-primary">{item.totalTrips}</p>
      <p className="text-lg md:text-2xl text-primary font-semibold ">{item.sumTotalTreesPlanted.toFixed(2)}</p>
    </div>
  );
}

export default LeaderboardEntry;
