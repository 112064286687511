import React from "react";
import Lottie from "lottie-react";
import trees from "../assets/animations/trees.json";
import leaf from "../assets/leaf.png";
import tw from "../../tailwind.config";
const TreeProgressBar = ({ treeProgressBar }: any) => {
  let { showProgressBar, maxValue, currentValue, UI } = treeProgressBar;

  if (!showProgressBar) {
    return null; // Return nothing if showProgressBar is false
  }
  console.log(UI);

  // Calculate the percentage of progress
  const percentage = (currentValue / maxValue) * 100;

  return (
    <div
      style={{ backgroundColor: UI.backgroundColor ?? "#460073" }}
      className="rounded-lg flex items-center max-w-5xl mx-auto"
    >
      <div className="flex-grow flex flex-col justify-center   p-3 rounded-lg  ">
        <h1
          style={{ color: UI.headingColor ?? "#07efd3" }}
          className=" text-secondary font-primary font-semibold text-lg md:text-2xl text-left items-center py-2 pl-5 "
        >
          TOTAL TREES PLANTED
        </h1>
        <h3
          style={{ color: UI.subheadingColor ?? "lightgrey" }}
          className="text-secondary font-primary font-medium text-xs md:text-sm text-left items-center pl-5"
        >
          We're actively <del>insuring</del> ensuring the wellbeing of both you and our planet. For every 20 miles you
          cycle, we further this cause by planting a tree. Join us in our mission and help us reach our target of
          ensuring the planting of 5,000 trees.
        </h3>
        <div className="flex items-center  my-5 mx-3 md:mx-7">
          {/* <p className="text-white text-sm md:text-xl font-medium ">0</p> */}
          <div className="flex-grow relative  mx-4">
            <div className="  ">
              <div className="rounded-2xl bg-white relative flex items-center">
                <div
                  style={{
                    width: `${percentage}%`,
                    height: "40px",
                  }}
                  className="bg-secondary rounded-2xl"
                ></div>
                <img
                  className="absolute right-0 top-1/2 transform bg-primary border-white  rounded-full border-4 -translate-y-1/2 h-14"
                  src={leaf}
                  alt="leaf"
                  style={{ right: `calc(${100 - percentage}% - 20px)`, height: "50px" }} // Adjust the "10px" value to position the leaf as you desire
                />
                <p
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  className="text-primary text-lg md:text-2xl font-medium z-10"
                >
                  {currentValue} / {maxValue}
                </p>
              </div>
            </div>
          </div>
          {/* <p className="text-white text-sm md:text-xl font-medium ">{maxValue}</p> */}
        </div>
      </div>
    </div>
    //OLD ATTEMPT
    // <div className="bg-primary rounded-lg flex items-center">
    //   <div className="flex-shrink-0 h-full w-1/4 items-center ">
    //     <div className="rounded-full m-5">
    //       <Lottie animationData={trees} loop={true} className="h-full" />
    //     </div>
    //   </div>
    //   <div className="flex-grow flex flex-col justify-center w-3/4  m-5 ml-0 rounded-md bg-white/30 py-5  ">
    //     <div className="flex-row h-1/2 items-center justify-around pl-4 md:pl-10 pb-2 ">
    //       <h1 className=" text-lightgrey font-primary font-medium h-1/2 text-lg md:text-2xl text-left items-center ">
    //         TREES PLANTED: {currentValue}
    //       </h1>
    //       <h3 className=" text-lightgrey italic font-primary font-medium h-1/2 text-xs md:text-lg text-left items-center ">
    //         {maxValue - currentValue} to go!
    //       </h3>
    //     </div>
    //     <div className="flex items-center  py-2 mx-3 md:mx-7">
    //       {/* <p className="text-white text-sm md:text-xl font-medium ">0</p> */}
    //       <div className="flex-grow relative  mx-4">
    //         <div className="  " style={{ width: "100%" }}>
    //           <div
    //             className=" rounded-2xl overflow-hidden bg-white"
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               position: "relative",
    //               height: "30px",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 width: `${percentage}%`,
    //                 height: "30px",
    //               }}
    //               className="bg-secondary rounded-2xl justify-center"
    //             ></div>
    //             <p
    //               style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
    //               className="text-primary font-medium "
    //             >
    //               {currentValue}/{maxValue}
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <p className="text-white text-sm md:text-xl font-medium ">{maxValue}</p> */}
    //     </div>
    //   </div>
    // </div>
  );
};

export default TreeProgressBar;
